import { extendTheme } from '@chakra-ui/react'
import {ButtonStyle as Button} from './ButtonStyle'

const theme = extendTheme({
    colors:{
        primary:{
            50: '#ddffed',
            100: '#b1fcd3',
            200: '#84f9b8',
            300: '#53f59c',
            400: '#26f282', //primary
            500: '#0dd968',
            600: '#01a950',
            700: '#007839',
            800: '#004921',
            900: '#001a07',
        },
        background:       
        {   //Black 2
            50: '#eceefb',
            100: '#cfd2e3',
            200: '#b1b6cd',
            300: '#929ab9',
            400: '#737fa5',
            500: '#59678b',
            600: '#45516d',
            700: '#313b4e',
            800: '#1c2430',
            900: '#050b15',
        }
    },
    config:{
        initialColorMode: "dark",
    },
    components:{
        Button
    }
})
/*
100:'#279EFF',
200:'#007DDA',
300:'#005EB7',
400:'#004194',
500:'#002773'

{
        50: '#f2ffdd',
        100: '#e0ffaf',
        200: '#cdfd7f',
        300: '#bafc4e',
        400: '#a7fb1e',
        500: '#8ee104',
        600: '#6daf00',
        700: '#4e7d00',
        800: '#2d4b00',
        900: '#0d1a00',
},
            
        50: '#e6f5fd',
        100: '#cadbe6',
        200: '#acc3d3',
        300: '#8dacc0',
        400: '#6c94ad',
        500: '#547a94',
        600: '#405f73',
        700: '#2c4453',
        800: '#182934',
        900: '#000f17',
        
        Black 2
{
        50: '#eceefb',
        100: '#cfd2e3',
        200: '#b1b6cd',
        300: '#929ab9',
        400: '#737fa5',
        500: '#59678b',
        600: '#45516d',
        700: '#313b4e',
        800: '#1c2430',
        900: '#050b15',
}

*/


export default theme