import React from 'react'
import { Spinner, Box } from '@chakra-ui/react'

function Loader() {
    return (
        <Box m={6} p={6}>
            <Spinner size='xl' thickness='5px' speed='0.5s'/>
        </Box>
    )
}

export default Loader
