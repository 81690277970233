import {useState, useReducer, useEffect} from 'react'
import { url } from '../../App'

function useUser() {

    const [ loadingUser, setLoadingUser ] = useState(false)
    const [ user, setUser ] = useReducer(userReducer, userInitialState)
    const token = localStorage.getItem('token')
  
    useEffect(()=>{
      if (token){
        setLoadingUser(true)
        console.log('Refreshing token...')

        get_access_token()
        .then(json=>{
          // Refresh token is still valid
          if (json.success){
            console.log('Token refreshed successfully.')

            localStorage.setItem('token', json.token)

            const cUser = json.user

            setUser({
              type:'user',
              _id:cUser._id, 
              email:cUser.email, 
              first_name:cUser.first_name, 
              last_name:cUser.last_name,
              savedWods: cUser.savedWods
            })
            setLoadingUser(false)
          }

          // Refresh token is no longer valid and must do complete login
          else{
            console.log('Invalid token, logging out.')
            setUser({type:'logout'})
            setLoadingUser(false)
          }
        })
      }
    },[])

    return { user, setUser, loadingUser }
}

export default useUser
const userInitialState = {
    _id: 0,
    email:'',
    first_name:'',
    last_name:'',
    savedWods: [],
    createdWods:[],
    isLoggedIn: false
  }
  
const userReducer = (state, action) => {
  switch(action.type){

    case 'saveWod':
      if (localStorage.getItem('token')){
        saveWod(state._id, action.wodID, true)
        .then(json=>{
          // The access token has expired
          if (!json.success){

            // Get new access token
            get_access_token()
            .then(json=>{

              //Success getting token
              if (json.success){
                localStorage.setItem('token', json.token)
                // Saving wod again
                saveWod(state._id, action.wodID, true)
                .then(json=>{
                  console.log('Wod Saved after refreshing token')
                })
              }
              
              // Refresh token expired
              else{
                logout()
                return userInitialState
              }
              
            })
          }
          
          // Saved wod succesfully
          else{
            console.log('Wod saved successfully')
          }
        })
      }
      else{        
        // The user is not logged in
        console.log('User are not logged in')
      }

      return {...state,
        savedWods: [action.value,...state.savedWods]
    }

    case 'unsaveWod':
      if (localStorage.getItem('token')){
        saveWod(state._id, action.wodID, false)
        .then(json => {
          if (!json.success){

            // Get new access token
            get_access_token()
            .then(json=>{

              //Success getting token
              if (json.access){
                localStorage.setItem('token', json.token)
                // Saving wod again
                saveWod(state.id, action.wodID, true)
                .then(json=>{
                  console.log('Wod unsaved after refreshing token')
                })
              }
              
              // Refresh token expired
              else{
                logout()
              }
              
            })
          }
          else {
            console.log('Wod unsaved successfully.')
          }
        })
      }
      else{
        console.log('User is not logged in')
      }
      let wods = state.savedWods.filter(e => e._id !== action.wodID)
      console.log(wods)
      return {...state,
        savedWods: [...wods]
      }

    case 'logout':
      logout()
      return userInitialState

    case 'user':
      return {...state,
        _id: action._id,
        email: action.email,
        first_name:action.first_name,
        last_name:action.last_name,
        savedWods:action.savedWods,
        isLoggedIn:true
      }
    
    case 'myWods':
      return {
        ...state,
        savedWods:action.savedWods,
        createdWods: action.createdWods,
      }

    default:
      return userInitialState
  }
}

const logout = () => {
  localStorage.removeItem('token')
}

export async function get_access_token(){
  const response = await fetch(`${url}/users/validation/`, {
    method:'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': `${localStorage.getItem('token')}`
    }
  })
  
  return await response.json()
}

async function saveWod(id, wodID, save){
  const response = await fetch(`${url}/workouts/save/`, {
    method:'POST',
    headers:{
      'Content-Type':'application/json',
      'x-access-token': `${localStorage.getItem('token')}`
    },
    body:JSON.stringify({
      userID:id,
      wodID:wodID,
      save: save
    })
  })
  
  return await response.json()
}

export async function get_saved_wods(){
  localStorage.getItem('access_wod_token')
  const response = await fetch(`${url}/workouts/saved/user/`, {
    method:'GET',
    headers:{
      'x-access-token':`${localStorage.getItem('token')}`,
    },
    
  })
  
  return await response.json()
}