export const ButtonStyle = {
    baseStyle: {},
    // styles for different sizes ("sm", "md", "lg")
    sizes: {},
    // styles for different visual variants ("outline", "solid")
    variants: {
        primary:{
            bg:'primary'
        },
    },
    // default values for `size` and `variant`
    defaultProps: {},
}
