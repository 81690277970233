import { ChakraProvider, Box } from '@chakra-ui/react'
import Homepage from './views/Homepage';
import {BrowserRouter} from 'react-router-dom'
import { useEffect, createContext, useState } from 'react'
import theme from './theme/theme';
import useUser from './components/User/useUser';
import { initGA } from './analytics/useGAnalytics';

export const url = 'http://workoutsapi.andrespty.com'
// export const url = 'http://localhost:5000'

export const seed = Math.floor(Math.random() * 101)

initGA()

function App() {
  const [ movesEquip, setMovesEquip ] = useState({})
  const { user, setUser, loadingUser } = useUser()

  useEffect(()=>{  
    fetch(`${url}/misc/load/`)
    .then(res=>res.json())
    .then(json => {
      if (json.success){
        setMovesEquip(json.data)
      }
    })
    .catch(err => console.log(err))
  }, [])

  return (
    <ChakraProvider theme={theme} resetCSS >
    <BrowserRouter>
      <MovesEquipmentContext.Provider value={{movesEquip}}>
      <SeedContext.Provider value={{seed}}>
      <UserContext.Provider value={{user, setUser, loadingUser}}>
    
        <Box h='100vh' bg={'background.800'} >
        
          <Homepage />
  
        </Box>

      </UserContext.Provider>
      </SeedContext.Provider>
      </MovesEquipmentContext.Provider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export const MovesEquipmentContext = createContext()
export const SeedContext = createContext()
export const UserContext = createContext()
export default App;
