import ReactGA from 'react-ga'

const trackerID = 'UA-201384218-2'

export const initGA = () => {
    ReactGA.initialize(trackerID)
}

export const GApageView = (page) => {
    ReactGA.pageview(page)
}

export const GAevent = (categoryName, eventName, labelName, value, interaction = false) => {
    ReactGA.event({
        category: categoryName,
        action: eventName,
        label: labelName,
        value: value,
        nonInteraction: interaction
    })
}

export const GAmodalView = (modalName) => {
    ReactGA.modalview(modalName)
}