import React, { useEffect, useReducer, Suspense, lazy, useState } from 'react'
import { Flex, Box, Spacer, Heading, Tabs, Center, LinkBox, LinkOverlay } from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'
import Loader from '../components/Loader'
import ReactGA from 'react-ga';
import { GApageView } from '../analytics/useGAnalytics';

const NavBar = lazy(()=>import('../components/NavBar'))
const Router = lazy(()=>import('../components/Router'))
const WorkoutDetailsModal = lazy(()=>import('./WorkoutDetails/WorkoutDetailsModal'))

function Homepage() {
    
    let history = useHistory()
    let { pathname } = useLocation()
    const [ initialLoad, setInitialLoad ] = useState(false)
    const [ index, setIndex ] = useReducer(indexBar)

    useEffect(()=>{
        if (!initialLoad){
            ReactGA.send('pageview')
            setInitialLoad(true)
        }
        else{
            ReactGA.set({ page: window.location.pathname });
            GApageView(window.location.pathname) // ReactGA.send('pageview')
        }
    }, [window.location.pathname])

    useEffect(()=>{
        setIndex(pathname)
    }, [pathname])

    const redirect = (url) => {
        history.push(url)
        setIndex(url)
    }

    return (
        <>
        <Box pt={6} mx={6}>
        <Tabs size='lg' variant='solid-rounded' index={index} colorScheme='primary'>

            <Flex alignItems='center' pb={3}>
                <LinkBox>
                    <Heading size='3xl'>
                        <LinkOverlay href='/'>
                            Workouts
                        </LinkOverlay>
                    </Heading>
                </LinkBox>

                <Spacer />
                
                <Suspense fallback={<Loader />}>
                    <NavBar redirect={redirect} index={index} />
                </Suspense>

            </Flex>
            
        </Tabs>
        </Box>

        <Suspense fallback={<Loader />}>

            <Center h='85vh' >
            
                <Router />

            </Center>

            <WorkoutDetailsModal />

        </Suspense>
        </>
    )
}

const indexBar = (index, action) => {

    const views = ['/', '/workouts', '/wod', '/myWods']
    return index = views.indexOf(action)
    
}

export default Homepage